import Vue from 'vue';
import VueRouter from 'vue-router';
import Alerts from '../views/Alerts.vue';
import AlertsMain from '../views/AlertsMain.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Alerts',
    component: Alerts,
    children: [
      {
        path: '',
        component: AlertsMain
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
