<template>
  <div id="app">
    <transition>
      <router-view />
    </transition>
    <r-notification />
    <r-modal/>
  </div>
</template>

<script>
export default {
  name: 'AlertsApp',

  beforeCreate() {
    document.getElementById('vue-yandex-maps')
      && document.getElementById('vue-yandex-maps').remove();

    window.ymaps && delete window.ymaps;
  }
};
</script>
<style lang="scss">
.flex-column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
</style>
