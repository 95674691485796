import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    objects: null,
    isObjectLoading: null
  },
  mutations: {
    setObjects(state, objects) {
      const details = {
        pattern: {
          id: 0,
          title: 'ГО ЧС'
        },
        patternName: 'ГО ЧС',
        formName: 'ГО ЧС',
        category: null,
        firm: null,
        fio: null,
        job: null,
        phones: [],
        dateFrom: null,
        dateTo: null,
        timeFrom: null,
        timeTo: null,
        description: null,
        coordinates: null,
        work: null,
        choicestEmails: [],
        choicestPhones: [],
        residentAddressees: [],
        addresses: [],
        peopleTotal: null,
        recipients: []
      };
      state.objects = objects.map(el => {
        const camelCaseObj = {};
        for (const elem in el) {
          camelCaseObj[elem.split('')[0].toLowerCase() + elem.slice(1)] = el[elem];
        }
        return {
          ...el,
          details: {
            ...details,
            ...el.details,
            camelCaseObj,
            dateTo: el?.details?.dateTo ? el?.details?.dateTo : camelCaseObj.dateTo,
            dateFrom: el?.details?.dateFrom ? el?.details?.dateFrom : camelCaseObj.dateFrom,
            description: el?.details?.description ? el?.details?.description : camelCaseObj.description,
            categoryData: el?.details?.categoryData ? el?.details?.categoryData : camelCaseObj.categoryData,
            recipients: el?.details?.recipients ? el?.details?.recipients : camelCaseObj.recipients,
          }
        };
      });
      console.log('ssssssssssssssssssss',state);
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    }
  },
  getters: {
  },
  actions: {
    async loadObjects(ctx) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList();
      ctx.commit('setObjects', res.all);
      ctx.commit('setObjectLoading', false);
    }
  },
  modules: {}
});
