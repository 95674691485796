import ApiService from './ApiService';

export default class PlaceApi extends ApiService {
  async getList() {
    const { data } = await this._axios.post('/ajax.php?action=getIncidents');

    return data;
  }
  async getIncidentTypes() {
    const { data } = await this._axios.get('/ajax.php?action=getIncidentTypes');
    return data;
  }
}
